import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import { ButtonTrace } from "../../components/Button"

const NotFoundPage = ({ data }) => {
  const header = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={header}
      alt={"Pehrson Not Found"}
      height={600}
      heightMobile={300}
    >
      <SEO
        title="Not Found"
        keywords={[
          "Beyond The Apprenticeship",
          "Matt Pehrson",
          "Not Found",
          "404",
        ]}
      />

      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>NOT FOUND</RuledBreak>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <ButtonTrace to="/" btn={3}>
            Go Home
          </ButtonTrace>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query notFoundQuery {
    header: allFile(filter: { name: { eq: "headerContact_1300x676" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`
export default NotFoundPage
